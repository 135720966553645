*, *:before, *:after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #040414;
  background-image: linear-gradient(to bottom, #040414 40%, #2a2a32);
  background-attachment: fixed;
  background-size: 100%;
  height: 100%;
}

button {
  font-family: 'Open Sans', sans-serif;
}